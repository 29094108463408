import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CurrentIDContext } from '../CurrentIDContext';
import { CheckPsw } from './components/CheckPsw';

import BACKEND_URL from '../config';

const HistoryQuestions = () => {
    const [questions, setQuestions] = useState([]);
    const { setCurrentID } = useContext(CurrentIDContext);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/check_questions`);
            setQuestions(response.data);
        } catch (error) {
            console.error('Error during request: ', error);
            alert('Error during request');
        }
    };

    const Completed = async(isCompleting, id) => {

        const formData = {
            isCompleted: isCompleting,
            currentID: id,
            option: 'Questions'
        }
    
        await fetch(`${BACKEND_URL}/completed`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });
  
        fetchData();
      }

    const CompletedTrue = (e) => {
        var id = e.target.id
        var isCompleting = true
        Completed(isCompleting, id)
      }
      const CompletedFalse = (e) => {
        var id = e.target.id
        var isCompleting = false
        Completed(isCompleting, id)
      }

    const [isCorrect, setIsCorrect] = useState(false);
    const [msg, setMsg] = useState('');

    useEffect(() => {
    const storedIsCorrect = localStorage.getItem('isCorrect');
    if (storedIsCorrect === 'true') {
        setIsCorrect(true);
    }
    }, []);

    useEffect(() => {
    localStorage.setItem('isCorrect', isCorrect.toString());
    }, [isCorrect]);

    return (
        <div style={{display: 'flex', justifyContent: 'center', fontFamily: '"Montserrat", sans-serif', marginTop: '200px'}}>
            <title>Список запитів</title>
            {isCorrect ?
            <div style={{marginTop: '-70px'}}>
            <div className='swipe-pages' style={{position: 'absolute', left: '10px', marginTop: '10px'}}>
                <Link style={{color: 'white'}} to='/check-questions'>Актуальні запити</Link>
            </div>
            <div style={{ marginBottom: '200px', display: 'flex', justifyContent: 'center' }}>
                <div>
                    <h2 style={{ textAlign: 'center' }}>Список усіх запитів до тех. підтримки:</h2>
                    {questions.sort((a, b) => new Date(b.Date) - new Date(a.Date) & b.isCompleted - a.isCompleted).map((el) => (
                        <div key={el.id}>
                                <div className='orders' style={{ position: 'relative', backgroundColor: el.isCompleted ? 'rgb(83, 83, 83)' : 'rgba(0, 188, 150, 0.328)'  }}>
                                <h4>Ім'я: {el.Name} {el.Surname} {el.Middlename}</h4>
                                <h4>Контактні дані: {el.EmailPhone}</h4>
                                <h4>Тема: {el.Theme}</h4>
                                <h4 style={{ marginBottom: '60px' }}>Дата: {el.Date}</h4>
                                <div style={{ position: 'absolute', left: '10px', marginTop: '-50px' }}>
                                    {(el.isCompleted === false) && <Link className="button-27" id={el.id} onClick={CompletedTrue}>Позначити як "Виконано"</Link>}
                                    {(el.isCompleted === true) && <Link className="button-27" id={el.id} onClick={CompletedFalse}>Позначити як "Не виконано"</Link>}
                                </div>
                                <div style={{ position: 'absolute', right: '10px', marginTop: '-50px' }}>
                                    <Link className="button-27" onClick={() => setCurrentID(el.id)} to='check-all'>Переглянути</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            </div>
            : 
            <div>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <h3 style={{position: 'absolute', top: '150px', color: 'red'}}>{msg}</h3>
              </div>
              <CheckPsw setIsCorrect={setIsCorrect} setMsg={setMsg}/>
            </div> 
          }
        </div>
    );
};

export {HistoryQuestions};
