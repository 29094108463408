import '../css/buy.css';
import { useState } from 'react';

import BACKEND_URL from '../../config';

const ChangeDesc = (props) => {

    const [changingDesc, setChangingDesc] = useState()

    const pushName = async(e) => {
        e.preventDefault()
        
        const formData = {
            'id': props.productID,
            'dbClass': props.productDBclass,
            'desc': changingDesc
        }

        try {
            await fetch(`${BACKEND_URL}/change_desc`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            props.closeWindow();
        } catch (error) {
            console.error('Error during deletion: ', error);
            alert('Error during deletion');
        }

    }

    return (
        <div>
            <div className="modal">
                <div className="modal-content">
                    <h2 style={{textAlign: 'center'}}>Поточний опис:</h2>
                    <div style={{textAlign: 'left', maxWidth: '500px'}}>{props.productDesc}</div>
                    <form>
                    <div className="group" style={{marginTop: '30px'}}>
                        <input 
                        type="text" 
                        required 
                        value={changingDesc}
                        style={{background: 'none', color: 'white'}} 
                        onChange={(e) => setChangingDesc(e.target.value)}
                        />
                        <span className="bar"></span>
                        <label>*Новий опис товару</label>
                    </div>
                    <button className='floating-button' onClick={props.closeWindow}>Закрити</button>
                    <button type='submit' className='floating-button' style={{marginLeft: '200px'}} onClick={pushName}>Змінити</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export { ChangeDesc };
