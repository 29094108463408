import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { Buy } from './components/Buy';
import { ViewDescription } from './components/ViewDescription';
import { ViewImages } from './components/ViewImages';

import BACKEND_URL from '../config';

import './css/buy.css'
import './css/spinner.css'

const IpIntercom = () => {
  const [IpIntercom, setIpIntercom] = useState([]);
  const [ListImages, setListImages] = useState([]);
  const [checkOrder, setCheckOrder] = useState(false);
  const [checkViewImages, setCheckViewImages] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [checkDesc, setCheckDesc] = useState(false)
  const [desc, setDesc] = useState()
  const [loading, setLoading] = useState(true)
  const [productName, setProductName] = useState("Default!");
  const [productPrice, setProductPrice] = useState("Default!");

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
    const response = await axios.get(`${BACKEND_URL}/get_product`, {
        params: { option: 'IpIntercom' }
        });
      setIpIntercom(response.data.get_list);
      setListImages(response.data.get_list_images);
      setLoading(false)
    } catch (error) {
      console.error('Error during request: ', error);
      alert('Error during request');
    }
  };

  function gettingEl(e) {
      setProductName(e.target.name)
      setProductPrice(e.target.id)
      setCheckOrder(true)
  }

  function viewImages(e, images) {
    setCurrentImages(images);
    setCheckViewImages(true);
    console.log(e)
  }

  function openWinDesc(e) {
    setDesc(e.target.id)
    setCheckDesc(true)
  }

  const closeWindow = () => {
    setCheckOrder(false);
    setCheckViewImages(false);
    setCheckDesc(false)
    fetchImages();
  };

  return (
    <>
    <div>
      <title>IP домофони</title>
      {loading ? <div>
              <div className="spinner"></div>
              <div className='text-snr'><p>ЗАВАНТАЖУЄМО КОНТЕНТ...</p></div>
            </div> :
            <div>
                 <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                {IpIntercom.map((image) => (
                  <div className='div-for-catalog-three' key={image.id}>
                    <p style={{ position: 'absolute', marginTop: '10px', fontSize: '25px' }}>{image.product}</p>
                    <p style={{ position: 'absolute', marginTop: '50px', textDecoration: 'underline', fontSize: '20px' }}>{image.price}грн</p>
                    <img
                      src={image.url}
                      alt={image.filename}
                      style={{ blockSize: '180px', marginTop: '80px'}}
                    />
                    <div style={{ position: 'absolute', display: 'flex', marginTop: '270px' }}>
                    {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).slice(0, 3).map((el) => (
                      <div style={{ margin: '5px' }} key={el.id}>
                        <img
                          src={el.url}
                          alt='none'
                          style={{ blockSize: '80px', border: '1px solid rgb(131, 131, 131)', borderRadius: '5px', cursor: 'pointer' }}
                          onClick={(e) => viewImages(e, ListImages.filter(el => image.id === el.idClass))}
                        />
                      </div>
                    ))}
                    {ListImages && ListImages.filter(el => image.id === el.idClass & image.dbClass === el.dbClass).length >= 4 && (
                        <div style={{ display: 'flex', marginTop: '50px'}}>
                          <p>+{ListImages.filter(el => image.id === el.idClass).length - 3}</p>
                        </div>
                      )}
                  </div>
                  <div style={{ position: 'absolute', marginTop: '350px', transform: 'scale(0.8)'}}>
                  {image.description.length > 60 ? 
                  <div>
                    <div className='description'>
                      <p>{image.description.slice(0, 57) + '...'}</p>
                    </div>
                    <div style={{marginLeft: '100px', width: '300px'}}>
                      <Link className='link-for-text' id={image.description} onClick={openWinDesc}>Переглянути увесь опис товару↴</Link>
                    </div>
                    </div>
                    : 
                    <div className='description'>
                      <p>{image.description}</p>
                    </div>}
                  </div>
                    <Link id={image.price} name={image.product} onClick={gettingEl} className='catalog-link' 
                    style={{ position: 'absolute', marginTop: '440px', width: '270px', transform: 'scale(0.8)' }}>
                      Замовити
                    </Link>
                  </div>
                ))}
              </div>
              </div>
            </div>}
        </div>
        {checkOrder &&<Buy productName={productName} productPrice={productPrice} closeWindow={() => setCheckOrder(false)}/>}
        {checkDesc && <ViewDescription desc={desc} closeWindow={closeWindow} />}
        {checkViewImages && <ViewImages images={currentImages} closeWindow={closeWindow} />}
      </>
  );
};

export { IpIntercom };
