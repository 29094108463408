import '../css/buy.css';
import { useState } from 'react';

import BACKEND_URL from '../../config';

const ChangePrice = (props) => {

    const [changingPrice, setChangingPrice] = useState()

    const pushPrice = async(e) => {
        e.preventDefault()
        
        const formData = {
            'id': props.productID,
            'dbClass': props.productDBclass,
            'price': changingPrice
        }

        try {
            await fetch(`${BACKEND_URL}/change_price`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            props.closeWindow();
        } catch (error) {
            console.error('Error during deletion: ', error);
            alert('Error during deletion');
        }

    }

    return (
        <div>
            <div className="modal">
                <div className="modal-content">
                    <h2 style={{textAlign: 'center'}}>Поточна ціна: {props.productPrice}грн</h2>
                    <form>
                    <div className="group" style={{marginTop: '30px'}}>
                        <input 
                        type="text" 
                        required 
                        value={changingPrice}
                        style={{background: 'none', color: 'white'}} 
                        onChange={(e) => setChangingPrice(e.target.value)}
                        />
                        <span className="bar"></span>
                        <label>*Нова ціна товару (без "грн")</label>
                    </div>
                    <button className='floating-button' onClick={props.closeWindow}>Закрити</button>
                    <button type='submit' className='floating-button' style={{marginLeft: '200px'}} onClick={pushPrice}>Змінити</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export { ChangePrice };
