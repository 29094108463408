import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { Buy } from './components/Buy';
import { Linking } from './components/Linking';

import BACKEND_URL from '../config';

const TestWatchImg = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      // Получаем изображения сразу
      const response = await axios.get(`${BACKEND_URL}/catalog_list`, {
        params: { option: 'AudioTubes' }
      });
      setImages(response.data);
    } catch (error) {
      console.error('Error during request: ', error);
      alert('Error during request');
    }
  }

  const [productName, setProductName] = useState("Default!");
  const [productPrice, setProductPrice] = useState("Default!");

  const [checkOrder, setCheckOrder] = useState(false);

  function gettingEl(e) {
      setProductName(e.target.name)
      setProductPrice(e.target.id)
      setCheckOrder(true)
  }

  return (
    <>
    <div>
      <title>Аудіо-трубки</title>
      <Linking />
    <div>
            <div style={{ overflow: 'hidden', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minWidth: '20px'}}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center'}}>
                {images.map((image) => (
                    <div className='div-for-catalog' key={image.id}>
                        <h3 style={{position: 'absolute'}}>Аудіотрубка</h3>
                        <h3 style={{position: 'absolute', marginTop: '40px'}}>{image.product}</h3>
                        <h3 style={{position: 'absolute', marginTop: '80px', textDecoration: 'underline'}}>{image.price}грн</h3>
                        <img
                          src={image.url}
                          alt={image.filename}
                          style={{blockSize: '180px', marginTop: '140px'}}
                        />
                        <Link name={image.product} id={image.price} onClick={gettingEl} className='catalog-link' style={{position: 'absolute', marginTop: '330px'}}>
                            Замовити
                        </Link>
                    </div>
                    ))}
                </div>
            </div>
        </div>
        </div>
        {checkOrder ?
          <div>
              <Buy productName={productName} productPrice={productPrice} closeWindow={() => setCheckOrder(false)}/>
          </div> 
          : <div></div>}
      </>
  );
};

export { TestWatchImg };
