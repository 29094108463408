import React from 'react';
import { Link } from 'react-router-dom';
import '../css/buy.css';

const ViewImages = ({ images, closeWindow }) => {
  return (
    <div className="modal">
      <div className="modal2" style={{ height: '500px', width: '800px', position: 'relative' }}>
        <Link
          className='dlt'
          style={{ position: 'absolute', top: '20px', right: '20px', color: 'white' }}
          onClick={closeWindow}
        >✖</Link>
        <div className="image-gallery" style={{ margin: '20px' }}>
          {images.map((image, index) => (
            <div key={index} style={{ display: 'inline-block', margin: '10px' }}>
              <img
                src={image.url}
                alt={image.filename}
                style={{ blockSize: '200px' }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { ViewImages };
