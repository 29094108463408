import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import './css/orders.css'
import { CheckPsw } from './components/CheckPsw';

import BACKEND_URL from '../config';

const CheckOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true)

    const [isCorrect, setIsCorrect] = useState(false);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const storedIsCorrect = localStorage.getItem('isCorrect');
        if (storedIsCorrect === 'true') {
        setIsCorrect(true);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('isCorrect', isCorrect.toString());
    }, [isCorrect]);
    

    useEffect(() => {
        fetch(`${BACKEND_URL}/check_orders`)
            .then(response => response.json())
            .then(data => {
                // Перетворення рядків дат в об'єкти Date та сортування за спаданням
                const sortedOrders = data.orders.sort((a, b) => new Date(b.Date) - new Date(a.Date));
                setOrders(sortedOrders);
                setLoading(false)
            })
            .catch(error => console.error('Помилка:', error));
    }, []); // Пустий масив потрібен, щоб повідомити, що ефект потрібно запускати лише один раз при розвертанні компоненту

    const Completed = async (e) => {
        const orderId = parseInt(e.target.value);
    
        const formData = {
            isCompleted: true,
            currentID: orderId,
            option: 'Orders'
        }
    
        await fetch(`${BACKEND_URL}/completed`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });
    
        // Видалити замовлення зі сторінки без оновлення
        setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
    }


    return (
        <div style={{display: 'flex', justifyContent: 'center', fontFamily: '"Montserrat", sans-serif', marginTop: '200px'}}>
        <title>Замовлення</title>
        {isCorrect ? 
        <div style={{marginTop: '-210px'}}>
        {loading ? <div>
            <div className="spinner"></div>
            <div className='text-snr'><p>ЗАВАНТАЖУЄМО КОНТЕНТ...</p></div>
          </div> :
        <div>
            <div className='swipe-pages' style={{position: 'absolute', right: '10px', marginTop: '-50px'}}>
                <Link style={{color: 'white'}} to='/history-orders'>Історія замовлень</Link>
            </div>
        <div style={{ marginTop: "200px", marginBottom: '200px', display: 'flex', justifyContent: 'center'}}>
            <div>
                {orders.filter(order => order.isCompleted === false).map((order) => (
                    <div className='orders' key={order.id} style={{position: 'relative'}}>
                        <h3>Товар: {order.Product}</h3>
                        <h3 style={{display: 'inline-block', margin: '0'}}>Ціна: {order.Price}грн</h3>
                        <h4>Ім'я: {order.Name} {order.Surname}</h4>
                        <h4>Контактні дані: {order.EmailPhone}</h4>
                        <h4>Адреса: {order.Middlename}</h4>
                        <h4 style={{marginBottom: '60px'}}>Дата: {order.Date}</h4>
                        <div style={{position: 'absolute', right: '10px', marginTop: '-50px'}}>
                            <button className="button-27" onClick={Completed} value={order.id}>Позначити як "Виконане"</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </div>}
        </div>
        : 
        <div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <h3 style={{position: 'absolute', top: '150px', color: 'red'}}>{msg}</h3>
          </div>
          <CheckPsw setIsCorrect={setIsCorrect} setMsg={setMsg}/>
        </div> 
        }
        </div>
    )
}

export { CheckOrders };
